.form-wrapper {
  width: 70%;
  padding-bottom: 9rem;
  padding-top: 5rem;
  position: relative;
}
.form-outer-wrapper {
  width: 70%;
  margin-top: 2rem;
}
.form-outer-wrapper form {
  width: 100%;
}

.form-wrapper form .row {
  margin-bottom: 3rem;
}

.form-wrapper form .row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.form-field {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  column-gap: 4rem;
  position: relative;
}

.name,
.email,
.country {
  width: 100%;
}

.form-wrapper form .row:nth-child(3) > div,
.form-wrapper form .row:nth-child(4) > div {
  width: 48%;
}

.heading-inner {
  margin-top: 2.5rem;
}

.heading-inner h2 {
  font-size: 2.2rem;
  font-weight: 500;
}

.questions-wrapper {
  padding: 3rem 0;
  overflow-y: auto;
  height: 66%;
}

.schedule-demo-wrapper {
  height: 100%;
}

.schedule-demo-top {
  display: flex;
  border-bottom: 1px solid #9a9a9a33;
}

.schedule-demo-left {
  width: 77%;
}


.schedule-demo-right {
  width: 23%;
  margin-top: 1rem;
}

@media (min-width: 1850px) {
  .form-outer-wrapper form {
    width: 70%;
  }
}

@media (max-width: 1440px) {
  .schedule-demo-left {
    width: 66%;
  }

  .schedule-demo-right {
    width: 34%;
  }
  .form-wrapper {
    width: 68%;
  }
  .form-outer-wrapper {
    width: 80%;
  }
}

@media (max-width: 1024px) {
  .schedule-demo-top {
    flex-direction: column;
  }

  .schedule-demo-left {
    width: 100%;
  }
  .schedule-demo-top {
    padding-bottom: 2rem;
  }
  .schedule-demo-right {
    width: 100%;
    margin-top: 0;
  }
  .form-outer-wrapper {
    width: 88%;
  }
}

@media (max-width: 768px) {
  .form-wrapper {
    margin-top: 3rem;
    width: 100%;
    padding-top: 0;
  }

  .form-outer-wrapper .row {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 3rem;
    margin-bottom: 3rem !important;
  }
  .form-outer-wrapper {
    width: 100%;
  }
  .form-outer-wrapper .row:nth-child(3) > div,
  .form-outer-wrapper .row:nth-child(4) > div {
    width: 100% !important;
  }
}
