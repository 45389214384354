/* Banner  */
.banner-section {
  background: #feeaec;
}
.banner-content-wrapper {
  position: relative;
  padding: 20rem 0 6rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.left-side {
  width: 53%;
  display: flex;
  flex-direction: column;
}
.right-side {
  width: 41%;
  transform: scale(0);
  transition: 0.8s ease;
  display: flex;
  justify-content: flex-end;
}

.banner-section h1 {
  font-family: "Lato";
  transform: translateY(50px);
  opacity: 0;
  transition: 0.6s;
}
.banner-content-wrapper p {
  font-size: 2.2rem;
  color: #7b7583;
  width: 90%;
  margin: 0 0 1rem;
}
.left-side > p {
  transform: translateY(50px);
  transition: 0.6s;
  opacity: 0;
}
.banner-button-wrapper {
  margin-top: 5rem;
  transform: translateY(50px);
  transition: 0.6s;
  opacity: 0;
  width: fit-content;
}
.banner-button {
  min-width: 172px;
  background: #740037;
  border: 1px solid #1d1d1d;
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  padding: 2rem 3rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}
.banner-button p {
  font-size: 1.8rem;
  font-weight: 700;
  margin: 0;
  width: 100% !important;
  color: #fff;
}
.elixir-logo {
  cursor: pointer;
}

/* Animate */
.banner-section.animate h1 {
  transform: translateY(0px);
  opacity: 1;
}
.animate .left-side > p {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.3s;
}
.banner-section.animate .banner-button-wrapper {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.6s;
}
.banner-section.animate .right-side {
  transform: scale(1);
}
.banner-button-wrapper{
  display: flex;
  gap: 20px;
}

@media (max-width: 1640px) {
  .left-side {
    width: 60%;
  }
  .right-side {
    width: 37%;
  }
}
@media (min-width: 1201px) and (max-width: 1440px) {
  .left-side {
    width: 61%;
  }
  .banner-section h1 {
    font-size: 4.3rem;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .left-side {
    width: 63%;
  }
  .banner-section h1 {
    font-size: 3.8rem;
  }
  .banner-content-wrapper p {
    font-size: 1.8rem;
  }
}
@media (max-width: 1024px) {
  .banner-content-wrapper {
    width: 100%;
    flex-direction: column;
    padding-top: 15rem;
    row-gap: 8rem;
  }
  .left-side {
    width: 100%;
  }
  .right-side {
    width: 100%;
    text-align: center;
  }
  .right-side img {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .right-side img {
    width: 90%;
  }
  .banner-button-wrapper{
    flex-direction: column;
  }
}
@media (max-width: 450px) {
  .banner-content-wrapper p {
    font-size: 13px;
  }
}
