.body-wrapper {
    background: #ffffff;
    border: 1px solid #feeaec;
    margin: 2rem 3rem;
    padding: 2rem;
    position: relative;
    display: flex;
    gap: 12rem;
    height: 100%;
  }
  .company-logo {
    width: fit-content;
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
  .error-message {
    color: red;
    font-size: 1.2rem;
    position: absolute;
    bottom: -25px;
  }
  
  @media (max-width: 1440px) {
    .body-wrapper {
      gap: 6rem;
      bottom: -16px;
    }
  }
  @media (max-width: 1150px) {
    .body-wrapper {
      gap: 3rem;
    }
  }
  @media (max-width: 1024px) {
    .error-message {
      bottom: -19px;
      font-size: 1.3rem;
    }
  }
  @media (max-width: 768px) {
    .body-wrapper {
      gap: 0;
      flex-direction: column;
      height: 100% !important;
    }
  }