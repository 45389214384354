.second-banner-inner {
  padding: 6rem 0;
  background: #a5f6d3;
  border: 1px solid #195865;
  text-align: center;
}
.second-banner-heading {
  width: 60%;
  margin: 0 auto;
}
.second-banner-heading h2 {
  color: #174e37;
  font-size: 4rem;
  font-weight: 700;
}
.second-banner-heading p {
  font-size: 1.8rem;
  color: #3f5a61;
}
.second-banner-button-wrapper {
  transition: 0.6s;
  width: fit-content;
  margin: 5rem auto 0;
}
.second-banner-button {
  background: #35a675;
  border: 1px solid #000;
  display: inline-block;
  padding: 2rem 4rem;
  text-align: center;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  min-width: 162px;
}
.second-banner-button p {
  font-size: 1.8rem;
  font-weight: 700;
  margin: 0;
  color: #fff;
  width: 100% !important;
}
@media (max-width: 1440px) {
  .second-banner-inner {
    padding: 4rem 0;
  }
  .second-banner-heading {
    width: 85%;
  }
}
@media (max-width: 1024px) {
  .second-banner-inner {
    padding: 4rem;
  }
  .second-banner-heading {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .second-banner-inner {
    text-align: left;
  }
  .second-banner-button-wrapper{
    flex-direction: column;
  }
  br {
    display: none;
  }
}
@media (max-width: 400px) {
  .second-banner-heading h2 {
    font-size: 3.5rem;
  }
  .second-banner-heading p {
    font-size: 1.6rem;
  }
}
