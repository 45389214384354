.features-section {
  background: #f9fafb;
}
.features-section .heading-tag {
  border: 1px solid #6a6868;
  background: #fcfbff;
}
.features-section .heading-tag span {
  color: #363636;
}
.features-section h2 {
  color: #000000;
}
/* Second Tab Switch */
.second-tab-switch-wrapper {
  margin-top: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.second-tab-switch-content {
  display: none;
  animation: fadeEffect 1s;
}
.second-tab-switch-content.active {
  display: block;
}
@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.tabs-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 40%;
}
.tabs-container > div {
  padding: 1rem 2rem;
  border: 1px solid transparent;
  border-radius: 12px;
  transition: all 0.2s ease;
  position: relative;
  cursor: pointer;
}
.tabs-container > div.active {
  background: #ffffff;
  border-color: #8316f9;
}
.tabs-container h3 {
  font-size: 2.4rem;
}
.tab-switch-paragraph {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.2s linear, max-height 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: opacity, max-height;
}
.second-tab-switch-body {
  width: 45%;
}
.tabs-container > div.active .tab-switch-paragraph {
  opacity: 1;
  max-height: 36em;
  transition: opacity 0s linear, max-height 1.2s linear;
  will-change: opacity, max-height;
}
.second-tab-switch-image {
  background: #fff;
  padding: 3rem;
  border: 3px solid #8316f9;
}
@media (min-width: 769px) and (max-width: 1024px) {
  .second-tab-switch-body {
    width: 55%;
  }
}
@media (max-width: 768px) {
  .second-tab-switch-wrapper {
    flex-direction: column;
    gap: 3rem;
    align-items: flex-start;
  }
  .tabs-container {
    width: 100%;
  }
  .second-tab-switch-body {
    width: 100%;
  }
}
@media (max-width: 400px) {
  .tabs-container {
    width: 100%;
  }
}
