.benefits-section {
  background: #e2deff;
}
.benefits-section .heading-tag {
  border: 1px solid #4a03a5;
  background: #d0caff;
}
.benefits-section .heading-tag span {
  color: #4a03a5;
}
.benefits-section h2 {
  color: #4a03a5;
}
.benefits-section .heading-description p {
  color: #4a03a5;
}
.benefits-cards-wrapper {
  margin-top: 8rem;
  row-gap: 3rem;
}
.benefits-card-inner {
  border: 1px solid #fff;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 2rem;
  position: relative;
  height: 100%;
  transition: all 0.3s ease;
  transform: translate(0);
  box-shadow: none;
}
.benefits-card-text h3 {
  color: #111827;
}

.benefits-card-inner:hover {
  border: 1px dashed;
  box-shadow: 0px 8px 20px 0px #00000040;
  cursor: default;
}
@media (max-width: 1024px) {
  .benefits-fourth-card {
    flex-direction: column;
    padding: 3rem;
  }
  .benefits-fourth-card div {
    width: 100%;
  }
}
