.sidebar-content img {
  width: 150px;
}
.sidebar-wrapper {
  background: #feeaec;
  background-repeat: no-repeat;
  padding: 5rem 5rem 0;
  position: relative;
  border: 2px solid #740037;
  height: 100%;
}

.sidebar-content {
  position: relative;
  z-index: 1;
}

.sidebar-content h1 {
  margin-top: 3rem;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  color: #3a3a3a;
}
.sidebar-content p {
  color: #3a3a3a;
}
.sidebar-image {
  margin-top: 3rem;
}
@media (max-width: 1150px) {
  .sidebar-wrapper {
    padding: 5rem 3rem 0;
  }
}

@media (max-width: 768px) {
  .sidebar-wrapper {
    padding: 5rem 3rem 3rem;
  }
  .sidebar-content {
    text-align: center;
  }
  .step-info p {
    display: none;
  }
  .step-info h2 {
    font-size: 1.5rem;
  }
  .sidebar-image {
    display: none;
  }
}
@media (max-width: 576px) {
  .sidebar-content {
    text-align: left;
  }
}
