.about-section {
  background: #f8ffe3;
}
.section-heading {
  text-align: center;
}
.heading-tag {
  padding: 5px 30px;
}
.heading-tag span {
  font-size: 2rem;
  font-weight: 600;
  line-height: 24px;
}
.heading-description p {
  width: 50%;
  margin: 0 auto;
}
.about-section .heading-tag {
  border: 1px solid #225d53;
  background: #e9feab;
}
.about-section .heading-tag span {
  color: #225d53;
}
.about-section h2,
.about-section p {
  color: #225d53;
}
.about-cards-wrapper {
  margin-top: 6rem;
  row-gap: 3rem;
}
.about-card-inner {
  background: #e9feab;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  height: 100%;
  transition: all 0.5s ease;
  position: relative;
}
.card-image img {
  width: 100%;
}
.about-section h3 {
  color: #182a27;
  margin-bottom: 0.5rem;
}

.about-card-inner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #225d53;
  box-shadow: none;
  transition: all 0.3s ease;
  pointer-events: none;
}
.about-card-inner:hover::before {
  border: 1px dashed;
  box-shadow: 0px 8px 20px 0px #00000040;
}
