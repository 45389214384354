.form-heading h1 {
  font-weight: 700;
  font-size: 2.8rem;
  margin: 0 0 0.5rem 0;
}
.buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.buttons-wrapper button {
  padding: 0.5rem 2.5rem;
  cursor: pointer;
}
.cancel-button {
  border: 1px solid #575757;
  background-color: #fff;
}
.cancel-button span {
  font-size: 1.5rem;
  font-weight: 500;
  color: #575757;
}
.submit-button {
  border: none;
  border: 1px solid #000000;
  transition: all 0.4s ease-in-out;
}

.submit-button span {
  font-size: 1.5rem;
  font-weight: 500;
  color: #ffffff;
}
.progress-bar-container {
  width: 100%;
  height: 5px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
}

.progress-bar {
  background-color: #154b9b;
  height: 100%;
  transition: width 0.3s ease;
}
.questions {
  display: none;
  transform: translateY(100px);
  opacity: 0;
  transition: all 0.5s ease;
}
.active-question {
  display: block;
  opacity: 1;
  transform: translateY(0);
}
.questions h3 {
  font-size: 1.8rem;
  font-weight: 600;
}
.answers {
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 7rem;
}
/* .buttons-wrapper span{
  margin: auto;
  } */
.answer-button {
  background: #f4f4f4;
  height: fit-content;
  padding: 1rem 2rem;
  border: none;
  border-radius: 38px;
  min-height: 4rem;
}
button.ans-selected {
  background: #093179;
}
button.ans-selected p {
  color: #ffffff;
}
.answers p {
  text-align: left;
  font-size: 1.4rem;
}
.new-answer {
  margin: 6rem 2rem 0 auto;
}

.input-box {
  margin-bottom: 3rem;
  position: absolute;
  bottom: 3rem;
  width: 98%;
}
.input-box input {
  width: 100%;
  border-radius: 15px;
  background: #f7fbff;
  border: 1px solid #79adf8;
  box-shadow: 0px 4px 8px 0px #0c3c6321;
  padding: 1.5rem 1rem;
  font-size: 1.8rem;
  font-weight: 500;
}

.send-button {
  padding: 0.7rem 2rem;
  border-radius: 12px;
  cursor: pointer;
  background: #006bff;
  border: 1px solid #006bff;
  position: absolute;
  right: 7px;
  top: 8px;
}
.send-button span {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 500;
}
.pick-time-wrapper {
  padding: 0 1.5rem;
}
.pick-time-header {
  text-align: center;
}
.pick-time-header h2 {
  font-size: 2.8rem;
  font-weight: 700;
  color: #0d5c9c;
}
.pick-time-header p {
  font-size: 1.2rem;
  color: #636363;
}
.dropdown-container {
  position: relative;
  padding: 0 !important;
  border: none !important;
}
.dropdown-container:hover {
  background-color: #fff !important;
}
.dropdown-header {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: fit-content;
  margin: 4px auto 0;
  gap: 1rem;
}
.dropdown-container span,
.dropdown-container > p {
  font-size: 1.5rem;
  color: #636363;
  line-height: 14px;
  text-align: center;
}
.dropdown-header img {
  width: 10px;
  height: 10px;
}
.dropdown-menu {
  position: absolute;
  top: 125%;
  left: 50%;
  transform: translate(-50%, 5%);
  width: 70%;
  border-radius: 4px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 5px;
  max-height: 200px;
  overflow-y: auto;
}
.dropdown-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}
.dropdown-item:hover {
  background-color: #efefef;
}
.time-slots {
  width: 92%;
  margin: 2rem auto 0;
  overflow: auto;
  height: 265px;
  padding-right: 1rem;
}
.time-duration-tab {
  border: 1px solid #e7e7e7;
  padding: 3px !important;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 74%;
  margin: 2rem auto 0;
  border-radius: 12px;
  cursor: pointer;
}
.time-duration-tab div {
  border-radius: 8px;
  padding: 1rem;
  width: 50%;
  text-align: center;
}
.time-duration-tab:hover {
  background: #fff !important;
}
.time-duration-tab div.active {
  background: #aecbff;
}
.time-duration-tab > div:hover {
  background-color: #efefef;
}
.time-duration-tab > div.active:hover {
  background-color: #aecbff !important;
}
.time-duration-tab div p {
  font-size: 12px;
  color: #636363 !important;
}
.time-duration-tab div.active p {
  color: #093179;
}

.time-slots > div {
  border: 1px solid #e7e7e7;
  text-align: center;
  padding: 1rem 1rem;
  margin-bottom: 1.2rem;
  border-radius: 12px;
  cursor: pointer;
}
.time-slots > div:hover {
  background-color: #efefef;
}

.time-slots p {
  font-size: 1.3rem;
  font-weight: 500;
  color: #504848;
}
.time-slot-picked {
  background: #093179;
}
.time-slot-disabled {
  background-color: #ececec;
  cursor: not-allowed;
}
.time-slot-disabled p {
  color: #aaa;
}
.time-slot-picked:hover {
  background-color: #093179 !important;
}
.time-slot-picked p {
  color: #ffffff;
}
.time-slot-disabled:hover {
  background-color: #ececec !important;
}
.scheduled-for {
  margin-top: 3rem;
  width: 46%;
  position: relative;
}
.scheduled-for h3 {
  font-size: 2rem;
  font-weight: 700;
  color: #353535;
}
.scheduled-date-time {
  background: linear-gradient(200.51deg, #093179 -9.13%, #115adf 135.22%);
  padding: 1.5rem 2rem;
  border-radius: 12px;
  margin-top: 1.5rem;
}

.scheduled-date > div,
.scheduled-time > div {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.scheduled-date > div span,
.scheduled-time > div span {
  font-size: 1.2rem;
  font-weight: 600;
  color: #fff;
}
.scheduled-date {
  margin-bottom: 3rem;
}
.scheduled-date-time p {
  font-size: 1.2rem;
  font-weight: 600;
  color: #fff;
}
.schedule-demo-bottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.schedule-demo-wrapper .buttons-wrapper {
  position: relative;
}

.submitted-box-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.submitted-box {
  width: 44%;
  background: #fff;
  height: 48%;
  border-radius: 32px;
  border: 1px solid rgba(229, 233, 235, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 70% !important;
  padding: 0 4rem;
}
.submitted-box * {
  margin-top: 0;
}
.submitted-box h3 {
  display: none;
}
.submitted-box .scheduled-for {
  margin-bottom: 3.5rem;
}
.submitted-box .scheduled-date-time {
  border-radius: 22px;
  padding: 2.5rem 1.5rem;
  background: #093179;
}

.scheduled-for > img {
  position: absolute;
  top: -17%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.submitted-box .scheduled-date-time > div span,
.submitted-box .scheduled-date-time > div span {
  font-size: 1.2rem;
}

.submitted-box .scheduled-date-time p {
  font-size: 1.4rem;
}
.submitted-message {
  text-align: center;
}
.submitted-message h2 {
  font-size: 3.2rem;
  font-weight: 700;
  color: #000000;
}
.submitted-message p {
  width: 68%;
  margin: 0 auto 3rem;
}
.done-button {
  padding: 1rem 4.5rem;
  border-radius: 12px;
  cursor: pointer;
  border: 1px solid #1e78ff;
  background-color: #fff;
}
.done-button span {
  font-size: 2rem;
  font-weight: 500;
  color: #1e78ff;
}
.error-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1440px) {
  .submitted-box {
    width: 60%;
  }
  .time-duration-tab {
    width: 100%;
  }
  .time-duration-tab div {
    width: 70%;
    text-align: center;
  }
}
@media (max-width: 1210px) {
  .time-slots {
    width: 100%;
  }
}

@media (max-width: 1105px) {
  .scheduled-for {
    width: 52%;
  }
}
@media (max-width: 1024px) {
  .form-heading h1 {
    /* margin: 0; */
  }
  .pick-time-wrapper {
    padding: 0 3rem;
  }
  .time-slots {
    display: flex;
    align-items: center;
    column-gap: 1.8rem;
    flex-wrap: wrap;
    row-gap: 2rem;
    margin: 2rem 0 0 1rem;
    padding: 0;
    height: 90px;
  }
  .time-slots > div {
    width: 30%;
    margin-bottom: 0;
  }
  .schedule-demo-bottom {
    flex-direction: column;
    align-items: center;
    gap: 5rem;
  }
  .scheduled-for h3 {
    text-align: center;
  }
  .scheduled-date > div,
  .scheduled-time > div {
    justify-content: center;
  }
  .scheduled-date-time p {
    text-align: center;
  }
  .submitted-box {
    width: 70%;
    height: 40%;
  }
  .time-duration-tab {
    width: 70%;
    margin-top: 2rem;
  }
  .time-duration-tab div {
    padding: 0.5rem;
  }
}
@media (max-width: 768px) {
  .form-heading {
    text-align: center;
  }
  .progress-bar-container {
    position: sticky;
    top: 2px;
    z-index: 1;
  }

  .pick-time-wrapper {
    padding: 0 4rem;
  }
  .pick-time-header h2 {
    margin: 0;
  }
  .dropdown-header {
    gap: 1rem;
  }
  .time-slots {
    margin-top: 2rem;
    justify-content: center;
    gap: 2rem;
    height: 125px;
  }
  .time-slots > div {
    width: 40%;
  }
  .scheduled-date-time {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 2.5rem 2rem;
    gap: 1rem;
  }
  .schedule-demo-wrapper .scheduled-for {
    width: 100%;
  }
  .schedule-demo-wrapper .scheduled-for .scheduled-date {
    margin: 0;
  }

  .schedule-demo-wrapper .buttons-wrapper {
    padding: 0 4rem;
  }
  .submitted-box {
    width: 85%;
    height: 60% !important;
  }
  .scheduled-for > img {
    width: 80px;
    top: -26%;
  }
  .input-box {
    width: 88%;
  }
}
@media (max-width: 576px) {
  .form-heading {
    text-align: left;
  }
  .pick-time-wrapper {
    padding: 0;
  }
  .time-slots > div {
    width: 46%;
  }
  .time-slots p {
    font-size: 1.4rem;
  }
  .schedule-demo-wrapper .scheduled-for {
    padding: 0;
  }
  .schedule-demo-wrapper .scheduled-for .scheduled-date-time > div {
    width: 50%;
  }
  .schedule-demo-wrapper .buttons-wrapper {
    padding: 0;
  }
  .scheduled-for {
    width: 75%;
  }
  .scheduled-for > img {
    width: 70px;
  }
  .submitted-message p {
    width: 90%;
  }
  .buttons-wrapper > button {
    padding: 0.5rem 2rem;
  }
  .cancel-button span,
  .submit-button span {
    font-size: 1.5rem;
  }
  .submitted-box {
    width: 95%;
    height: 70% !important;
  }
  .submitted-box-overlay .scheduled-for {
    padding: 0 !important;
  }
}
