.solutions-section {
  background: #fff7dd;
}
.solutions-section .heading-tag {
  border: 1px solid #d7a200;
  background: #ffe9a7;
}
.solutions-section .heading-tag span {
  color: #b18606;
}
.solutions-section h2 {
  color: #d7a200;
}

/* First Tab Switch */
.first-tab-switch-wrapper {
  margin-top: 6rem;
}
.first-tab-switch-content {
  display: none;
  animation: fadeEffect 1s;
}
.first-tab-switch-content.active {
  display: block;
}
@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.tab-links-container {
  display: flex;
  background: #ffe9a7;
  gap: 3rem;
  padding: 1rem 2rem;
  width: fit-content;
  border-radius: 12px;
  margin: 6rem auto;
}
.tab-links-container div {
  padding: 1rem 3rem;
  background: #ffffff;
  border-radius: 12px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tab-links-container p {
  font-family: Manrope;
  font-size: 22px;
  font-weight: 500;
  margin: 0;
  transition: 0.3s ease;
}
.tab-links-container .active {
  background: #740037;
}
.tab-links-container .active p {
  color: #fff;
}
.tab-switch-inner {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.tab-switch-image {
  width: fit-content;
  display: flex;
  justify-content: flex-end;
  background: #fff;
  padding: 3rem;
  border: 3px solid #ffc000;
}
.tab-switch-text {
  width: 45%;
  z-index: 1;
}

.tab-switch-text ul {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.tab-switch-text h3 {
  font-size: 3.5rem;
}
@media (max-width: 1200px) {
  .tab-switch-inner {
    justify-content: space-between;
  }
  .tab-switch-text {
    width: 45%;
  }
}
@media (max-width: 1024px) {
  .tab-links-container div {
    padding: 1rem 1.9rem;
  }
}
@media (max-width: 768px) {
  .tab-switch-inner {
    flex-direction: column;
    gap: 4rem;
  }
  .tab-switch-text,
  .tab-switch-image {
    width: 100%;
  }
  .tab-links-container {
    flex-direction: column;
    width: 70%;
  }
}
@media (max-width: 400px) {
  .tab-links-container {
    width: 100%;
  }
}
