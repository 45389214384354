.video-container {
	z-index: 12;
	background-color: rgba(243, 243, 243, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	pointer-events: none;
	transition: all 0.3s;
}
.video-container-inside {
	position: relative;
}
.video-container .close {
	position: absolute;
	top: -60px;
	right: -60px;
	cursor: pointer;
	z-index: 99;
	width: 50px;
}
.video-container iframe {
	border: 6px solid #42a3e8;
}
.video-section-inner iframe {
	min-height: 500px;
	width: 100%;
	border-radius: 24px;
}
/* @keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 #f9bac0;
    box-shadow: 0 0 0 0 #f9bac0;
  }
  70% {
    -moz-box-shadow: 0 0 0 8px #f9bac0;
    box-shadow: 0 0 0 10px #f9bac0;
  }
  100% {
    -moz-box-shadow: 0 0 0 0 #f9bac0;
    box-shadow: 0 0 0 0 #f9bac0;
  }
} */
.show {
	pointer-events: all;
	opacity: 1;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
@media (max-width: 576px) {
	.video-container .close {
		right: 0;
	}
}
.modal {
	position: relative;
}

.video-section-wrapper {
	position: relative;
	background: #ffffff;
	border-radius: 32px;
	padding: 18px;
	margin: 4rem auto 4rem auto;
	width: 80%;
	transition: all 0.2s ease 0s;
	animation: pulse 2s infinite;
}
.video-section-inner {
	background-image: url("../../assets/agentlavel-thumnail.png");
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 505px;
	border-radius: 34px;
}
.video-section-button {
	cursor: pointer;
	z-index: 10;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 100px;
	background: #ffffff;
	padding: 30px;
	display: flex;
	gap: 20px;
	align-items: center;
}
.video-section-button img {
    filter: drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.5));
}
.video-section-button img {
	height: 100px;
	width: 100px;
}
.button-text p {
	font-size: 2rem;
	font-weight: 600;
	line-height: 1;
	color: #1c1f25;
}

.button-text span {
	font-size: 1.6rem;
	margin-bottom: 0.4rem;
	margin-right: 0.8rem;
}
.button-text span:last-child {
	font-weight: 500;
	color: #387ff5;
}
.dot {
	height: 4px;
	width: 4px;
	background: #32448b33;
	border-radius: 50%;
	display: inline-block;
}
.video-section-button button {
	display: none;
	height: 212px;
	width: 212px;
	background: white;
	border: 0;
	border-radius: 50%;
	background-image: url(https://f.hubspotusercontent30.net/hubfs/9471087/resources/xenonstack-usecase-playbutton.svg);
}
.video-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99999;
	background-color: rgba(243, 243, 243, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	pointer-events: none;
	transition: all 0.3s;
}
.video-container-inside {
	position: relative;
}

.video-container iframe {
	border: 6px solid #42a3e8;
}
.show {
	pointer-events: all;
	opacity: 1;
	top: 60px !important;
}
.video-section{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.cancel-button-wrapper{
    display: flex;
    justify-content: center;
    /* margin-right: 5rem; */
    margin-top: 1rem;
}
.cancel-button-wrapper button{
    background: #740037;
    border: 1px solid #1d1d1d;
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 2rem;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
}
.video-header-wrapper{
	border-bottom: 1px solid #cfcfcf;
}
.video-header button{
    padding: 0.5rem 2.5rem;
    cursor: pointer;
	border: 1px solid #575757;
    background-color: #fff;
	font-size: 1.5rem;
    font-weight: 500;
    color: #575757;
}
.video-header{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 1rem auto 1rem auto;
	padding: 0 3rem;
    width: 80%;
}
@media (max-width: 1023px) {
}
@media (max-width: 767px) {
	.video-section-inner {
		min-height: 505px;
	}
	.video-header{
        width: 100%;
        padding: 0 3rem;
    }
	.video-section-wrapper {
		width: 100%;
	}
	.video-section-button > img {
		display: none;
	}
	.button-text {
		display: none;
	}
	.video-section-button button {
		display: block;
	}
	.video-section-button {
		padding: 0;
	}
	.video-section-button {
		border: 6px solid #c3d1ff59;
		border-radius: 50%;
	}
	.video-section-button button {
		height: 130px;
		width: 130px;
	}
}
@media (max-width: 768px){
  .demo{
    margin-bottom: 100px;
  }
}
@media (max-width: 576px) {
	.video-section-button button {
		height: 80px;
		width: 80px;
	}
}


.video-sidebar-content img {
    width: 150px;
  }
  .video-sidebar-wrapper {
    background: linear-gradient(to bottom, #FFFAFA 60%, #FEEAEC 40%);
    background-repeat: no-repeat;
    padding: 2rem 3rem 0;
    position: relative;
    height: 100%;
  }
  
  .video-sidebar-content {
    position: relative;
    z-index: 1;
  }
  
  .video-sidebar-content h1 {
    margin-top: 3rem;
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
    color: #3a3a3a;
  }
  .video-sidebar-content p {
    color: #3a3a3a;
  }
  .sidebar-image {
    margin-top: 3rem;
  }
  .top-video-title{
	display: flex;
	flex-direction: column;
	justify-content: center;
  }
  .top-video-title h1{
	font-size: 3.8rem;
	text-align: center;
  }
  .top-video-title p{
	text-align: center;
  }
  @media (max-width: 1150px) {
    .video-sidebar-wrapper {
      padding: 5rem 3rem 0;
    }
  }
  
  @media (max-width: 768px) {
    .video-sidebar-wrapper {
      padding: 5rem 3rem 3rem;
    }
    .step-info p {
      display: none;
    }
    .step-info h2 {
      font-size: 1.5rem;
    }
    .sidebar-image {
      display: none;
    }
  }
  @media (max-width: 576px) {
    .video-sidebar-content {
      text-align: left;
    }
  }
  